.input-calendar {
    *:not(.form-control) {
        box-sizing: initial;
    }

    .form-control.is-invalid, 
    .was-validated .form-control:invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + .75rem);
        background: none;

        ~ svg {
            color: #dc3545;
        }
    }

    .form-control.is-valid, .was-validated .form-control:valid {
        border-color: #75b798;
        padding-right: calc(1.5em + .75rem);
        background: none;

        ~ svg {
            color: #75b798;
        }
    }

    .form-control ~ svg {
        margin-right: .25rem;
    }
}

.input-datetime {
    display: flex;
    border: 1px solid;

    &.form-control {
        padding: 0;
        border-color: #ced4da;

        &:not(.disabled).focus {
            color: #212529;
            background-color: #fff;
            border-color: #86b7fe;
            outline: 0;
            box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
        }

        &.is-valid {
            background: none;
            border-color: #75b798;
            
            &.focus {
                border-color: #75b798;
                box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb),.25);
            }
        }
        
        &.is-invalid {
            background: none;
            border-color: #dc3545;

            &.focus {
                border-color: #dc3545;
                box-shadow: 0 0 0 .25rem rgba(220,53,69,.25);
            }
        }
    }

    .input-datetime-date input,
    .input-datetime-time {
        color: inherit;
        font-style: inherit;
        font-weight: inherit;
        background-color: inherit;
        text-align: inherit;
        border: none;
        outline: 0;
        border-radius: 0;
        padding: .375rem .75rem;
    }

    .input-datetime-date {
        width: 65%;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;

        input {
            width: calc(100% - 1.5rem);
            height: 100%;
            margin: 0;
        }

        *:not(.form-control) {
            box-sizing: initial;
        }
    }
    .input-datetime-time {
        width: 35%;
        border-left: 1px solid;
        border-color: inherit;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
    }
}
