.navbar {
    > div {
        padding: 0 2rem 0 1.5rem;
    }

    .bs-navbar-toggler {
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        font-size: 1.25rem;
        border-radius: 50%;
        border: none;
        background-color: transparent;
    
        &:hover {
            background-color: rgba(0,0,0, .05);
        }
    }

    .nav-link {
        white-space: nowrap;
    }

    .content-links {
        border-bottom-left-radius: 1.25rem;
        border-top-left-radius: 1.25rem;
    }
    .content-avatar {
        align-items: stretch;
        border-bottom-right-radius: 1.5rem;
        border-top-right-radius: 1.5rem;    
    }
}

.navbar-dark {
    --bs-navbar-nav-link-padding-x: 1rem;
    background-color: var(--umss-blue);

    .navbar-nav.content-links {
        > .nav-link:first-child {
            padding-left: 1.25rem ;
            border-bottom-left-radius: 1.25rem;
            border-top-left-radius: 1.25rem;
        }
    }
    .nav-link {
        padding-top: .75rem;
        padding-bottom: .75rem;
        color: #fff;

        &.active {
            background-color: #fff;
            color: var(--umss-red);
        }
    }
    .nav-item.dropdown.active .nav-link {
        background-color: #fff;
        color: var(--umss-red);
    }

    .bs-navbar-toggler {
        color: #fff;
    
        &:hover {
            background-color: rgba(255,255,255, .05);
        }
    }

    .content-links,
    .content-avatar {
        background-color: var(--umss-red);
    }
}

@media (min-width: 992px) {
    .navbar > div {
        padding: 0 5rem;
    }
}

@media (max-width: 992px) {
    .navbar .content-avatar {
        margin-left: auto;
        border-bottom-left-radius: 1.25rem;
        border-top-left-radius: 1.25rem;

        > .nav-link {
            border-left: 0 !important;
        }
    }

}