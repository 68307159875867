.btn {
    display: inline-flex;
    align-items: center;
    text-align: center;

    &.btn:not([class*='btn-']) {
        border: 0;
    }

    .bs-btn-icon,
    .bs-btn-icon-end, 
    .bs-btn-icon-start {
        display: inline-block;
        user-select: none;
    }

    .bs-btn-icon-end:not(:first-child) {
        margin-left: .5rem;
    }
    .bs-btn-icon-start:not(:last-child) {
        margin-right: .5rem;
    }

    .bs-btn-label {
        transition-duration: 0.2s;
        flex: 1 1 auto;
    }

    .pi {
        line-height: 1.5;
    }
}

.btn {
    
    &.btn-icon, 
    &.btn-icon-lg, 
    &.btn-icon-sm {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1;
        padding: 0;

        &.btn-icon-rounded {
            border-radius: 50%;
        }
        .spinner-border {
            vertical-align: 0;
        }

    }
    &.btn-icon-sm {
        height: 1.75rem;
        font-size: 1rem;
    }
    &.btn-icon {
        height: 2.25rem;
        font-size: 1.25rem;
    }
    &.btn-icon-lg {
        height: 2.75rem;
        font-size: 1.45rem;
    }

}


.btn-check:checked + .btn, 
.btn.active, 
.btn.show, 
.btn:first-child:active, 
:not(.btn-check) + .btn:active {
    background-color: var(--bs-btn-active-bg);
}

.btn a {
    color: inherit;
    text-decoration: none;
}
