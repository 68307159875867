input[type='checkbox'],
input[type='radio'] {
    cursor: pointer;
}

.label-center label {
    display: block;
    text-align: center;
}

.form-check-label {
    white-space: nowrap;
}

.form-check-input {
    margin: 0;
}