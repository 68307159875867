.bs-menu {
    display: inline-block;

    .dropdown-item {
        cursor: pointer;
    }

    .dropdown-toggle::after {
        content: none;
    }
}
