.editor-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #ced4da;
    border-radius: .375rem;
    background: #fff;

    .editor-container {
        flex: 1;
        width: 100%;
        padding: 1rem;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.toolbar-grid {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 1fr 1fr;
    padding: .25rem;
    border-bottom: 1px solid #ced4da;

    & > button {
        margin-right: .25rem;
        font-size: 1rem;
        padding: .5rem;
        border: none;
        border-radius: .25em;
        background: #fff;
        cursor: pointer;
        line-height: 1;

        &:hover {
            background-color: #6c757d40;
        }
    }
}

.superFancyBlockquote {
    color: #999;
    background: #fff;
    font-family: "Hoefler Text", Georgia, serif;
    font-style: italic;
    border-left: 2px solid #999;
    padding-left: 10px;
}

.DraftEditor-codeblock {
    background-color: rgba(0,0,0,.05);
    font-size: 16px;
    padding: 20px;
}

.DraftEditor-alignJustify .public-DraftStyleDefault-block {
    text-align: justify;
}

