// DataTable v1.0.1
.datatable {
    width: 100%;

    &.hide-header thead {
        visibility: collapse;
    }

    .table-wrapper:not(:last-child) {
        border-bottom: 2px solid #dee2e6;
    }

    table {
        width: 100%;
        margin: 0;

        thead {
            vertical-align: middle;
    
            tr > * {
                padding: .75rem 1rem;

                .columnheader-container {
                    display: flex;
                    align-items: center;
                }
    
                &.sortable {
                    cursor: pointer;
                }
                &.header-start .columnheader-container {
                    text-align: left;
                    justify-content: left;
                    .sortable-icon {
                        text-align: left;
                    }
                }
                &.header-end .columnheader-container {
                    text-align: right;
                    justify-content: right;
                    .sortable-icon {
                        order: -1;
                        text-align: right;
                    }
                }
                &.header-center .columnheader-container {
                    text-align: center;
                    justify-content: center;
                    .sortable-icon {
                        text-align: center;
                    }
                }
            }
            .table-filters > * {
                padding: .5rem 1rem;
            }
        }
        tbody, tfoot {
            vertical-align: middle;
            border-top: 3px solid #dee2e6;
            
            tr > * {
                padding: .5rem 1rem;
            }
        }
        tr {
            &.row-selectable {
                cursor: pointer;
            }
            &.row-selectable {
                &.selected > * {
                    color: #0d6efd;
                }
                &.disabled > * {
                    color: #495057;
                    cursor: default !important;
                }
            }
            &.row-subheader {
                font-weight: bold;
            }
            &:not(.disabled):focus {
                outline: 0;

                > * {
                    background-color: #c2dbfe;
                }
            }
        }
    }
    
    .table-hover > tbody > tr.row-disabled:hover > * {
        --bs-table-accent-bg: transparent;
        color: inherit;
    }

    .sortable-icon {
        padding: .5rem;
        visibility: hidden;
        line-height: 1em;
        border-radius: 50%;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
        i {
            height: 1em;
            width: 1em;
        }
    }
    tr > *:hover .sortable-icon, 
    tr > *.sorted-column .sortable-icon {
        visibility: visible;
    }
    tr > *.sorted-column {
        color: #0d6efd;
        cursor: pointer;
    }

    // Selector
    .datarow-selector {
        width: 100%;
        text-align: center;
        line-height: 1;

        input {
            width: 1.25em;
            height: 1.25em;
            margin: 0;
            border-radius: .25em;
            cursor: pointer;
        }
    }

    // Pagination
    .datatable-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .25rem;

        .btn-pagination {
            --bs-btn-border-width: 0;
            --bs-btn-color: #102644;
            --bs-btn-hover-color: #102644;
            --bs-btn-hover-bg: #6c757d40;
            --bs-btn-focus-shadow-rgb: 108, 117, 125;
            --bs-btn-active-color: #fff;
            --bs-btn-active-bg: #102644;
            --bs-btn-active-border-color: #6c757d;
            --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --bs-btn-disabled-color: #6c757d;
            --bs-btn-disabled-bg: transparent;
            --bs-btn-disabled-border-color: #6c757d;
            --bs-gradient: none;
        }

        .page-size-options {
            > button {
                border: 1px solid #ced4da;
    
                > span {
                    margin-right: .25rem;
                }
            }
            .dropdown-menu {
                min-width: 100%;
                max-width: 100%;

                .dropdown-item {
                    cursor: pointer;
                }
            }
        }
    }

}

/* xxl > 1400 */
@media (min-width: 1400px) {
    .datatable .table-style-1 {
        font-size: 1.1rem;
    }
}

.datatable {
    .btn, 
    .table-options, 
    .datatable-options {
        white-space: nowrap;
    }
    tr > * .dropdown {
        width: 100%;
    }
} 

.datatable-toolbar {
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    border-bottom: 1px solid #c7cace;
    padding: 1rem;
}
