.input-layout {
    &:not(.only-input):not(.inline) {
        margin-bottom: 1rem;
    }

    > .input-dropdown, 
    > .editable-select,
    > .input-calendar {
        width: 100%;
    }

    .info-icon {
        margin-left: .35rem;
        cursor: pointer;

        &:focus {
            outline: 0;
        }
    }

    &.only-input {
        label {
            display: none;
        }
    }

    &.inline,
    &[class*="inline-"] {
        &.input-formik {
            flex-wrap: wrap;
    
            > :nth-child(2) {
                flex: 1;
            }
            .invalid-feedback {
                margin-top: 0;
                text-align: center;
            }
        }
    }

    &.inline {  
        display: flex;
        align-items: center;
    
        > label {
            margin-right: .75rem;
            margin-bottom: 0;
            white-space: nowrap;
        }
    }

}

// sm
@media (min-width: 576px) {
    .input-layout.inline-sm {
        display: flex;
        align-items: center;

        > label {
            margin-right: .75rem;
            margin-bottom: 0;
            white-space: nowrap;
        }
    }
}
// md
@media (min-width: 768px) {
    .input-layout.inline-md {
        display: flex;
        align-items: center;

        > label {
            margin-right: .75rem;
            margin-bottom: 0;
            white-space: nowrap;
        }
    }
}
// lg
@media (min-width: 992px) {
    .input-layout.inline-lg {
        display: flex;
        align-items: center;

        > label {
            margin-right: .75rem;
            margin-bottom: 0;
            white-space: nowrap;
        }
    }
}
// xl
@media (min-width: 1200px) {
    .input-layout.inline-xl {
        display: flex;
        align-items: center;

        > label {
            margin-right: .75rem;
            margin-bottom: 0;
            white-space: nowrap;
        }
    }
}
// xxl
@media (min-width: 1400px) {
    .input-layout.inline-xxl {
        display: flex;
        align-items: center;

        > label {
            margin-right: .75rem;
            margin-bottom: 0;
            white-space: nowrap;
        }
    }
}
