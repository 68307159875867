/* InputCheckbox */
.input-checkbox {
    display: inline-block;
    height: 1em;
    width: 1em;
    cursor: pointer;
    border: 1px solid #ced4da;
    border-radius: 25%;
    aspect-ratio: 1;

    &.disabled {
        pointer-events: none;
        filter: none;
        opacity: .5;
    }

    &:focus {
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
    }
    
    .input-checkbox-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 23%;
        line-height: 1;
        
        &[class*="bi-"] {
            background-color: var(--bs-blue);
            color: #fff;
        }

        &::before {
            line-height: 1;
        }
    }

}
