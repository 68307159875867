.bs-dialog {

    .bs-modal-dialog {
        height: 100%;
        width: 100%;
        min-width: 360px;
        min-height: 100%;
        padding: .5rem;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .modal-content {
            width: 25rem;
    
            .modal-header {
                padding: .5rem 1rem .25rem 1rem;
        
                .btn-close {
                    margin: 0;
                }
            }
            .dialog-message {
                display: flex;
                align-items: center;
                gap: 1rem;
        
                .dialog-message-icon {
                    width: 2.5rem;
                    height: 2.5rem;
                    font-size: 2.5rem;
                    line-height: 1;
        
                    [class*=" bi-"]::before {
                        vertical-align: 0;
                    }
                }
            }
            .modal-footer {
                padding: .25rem 1rem .5rem 1rem;
        
                & > * {
                    margin: 0;
                    
                    &:not(:last-child) {
                        margin-right: .5rem;
                    }
                }
            }
        }
    }
}

.modal.level-2 {
    --bs-modal-zindex: 1155;
}
.modal.level-3 {
    --bs-modal-zindex: 1255;
}

.modal-backdrop.level-2 {
    --bs-backdrop-zindex: 1150;
}
.modal-backdrop.level-3 {
    --bs-backdrop-zindex: 1250;
}
