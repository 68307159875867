.scroll-up {
    position: fixed;
    bottom: 5rem;
    right: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1250;
    cursor: pointer;
    opacity: 0;
    transition: opacity .5s;

    &.show {
        opacity: 1;
    }

    span {
        color: white;
        font-size: 1.25rem;
    }
}