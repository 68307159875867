@import 'themes/umss/bundle';

.btn-blue {
    --bs-btn-color: #fff;
    --bs-btn-bg: #102644;
    --bs-btn-border-color: #102644;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #12366a;
    --bs-btn-hover-border-color: #133c77;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #133c77;
    --bs-btn-active-border-color: #134183;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #102644;
    --bs-btn-disabled-border-color: #102644;
}
.btn-red {
    --bs-btn-color: #fff;
    --bs-btn-bg: #A60322;
    --bs-btn-border-color: #A60322;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ca0025;
    --bs-btn-hover-border-color: #F00019;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #F00019;
    --bs-btn-active-border-color: #F00019;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #A60322;
    --bs-btn-disabled-border-color: #A60322;
}

.btn-outline-blue {
    --bs-btn-color: #102644;
    --bs-btn-border-color: #102644;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #102644;
    --bs-btn-hover-border-color: #102644;
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #102644;
    --bs-btn-active-border-color: #102644;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #102644;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #102644;
    --bs-gradient: none;
}
.btn-outline-red {
    --bs-btn-color: #A60322;
    --bs-btn-border-color: #A60322;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #A60322;
    --bs-btn-hover-border-color: #A60322;
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #A60322;
    --bs-btn-active-border-color: #A60322;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #A60322;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #A60322;
    --bs-gradient: none;
}

/* New variant bootstrap btn-text- */
.btn-text-blue {
    --bs-btn-color: #102644;
    --bs-btn-hover-color: #102644;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #102644;
    --bs-btn-active-bg: #10264440;
    --bs-btn-active-border-color: #102644;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #102644;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #102644;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-text-red {
    --bs-btn-color: #A60322;
    --bs-btn-hover-color: #A60322;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #A60322;
    --bs-btn-active-bg: #A6032240;
    --bs-btn-active-border-color: #A60322;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #A60322;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #A60322;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-text-primary {
    --bs-btn-color: #0d6efd;
    --bs-btn-hover-color: #0d6efd;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #0d6efd;
    --bs-btn-active-bg: #0d6efd40;
    --bs-btn-active-border-color: #0d6efd;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0d6efd;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0d6efd;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-text-secondary {
    --bs-btn-color: #6c757d;
    --bs-btn-hover-color: #343a40;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #6c757d;
    --bs-btn-active-bg: #6c757d40;
    --bs-btn-active-border-color: #6c757d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #6c757d;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-text-success {
    --bs-btn-color: #198754;
    --bs-btn-hover-color: #198754;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 25, 135, 84;
    --bs-btn-active-color: #198754;
    --bs-btn-active-bg: #19875440;
    --bs-btn-active-border-color: #198754;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #198754;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #198754;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-text-info {
    --bs-btn-color: #0dcaf0;
    --bs-btn-hover-color: #0dcaf0;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 13, 202, 240;
    --bs-btn-active-color: #0dcaf0;
    --bs-btn-active-bg: #0dcaf020;
    --bs-btn-active-border-color: #0dcaf0;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0dcaf0;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0dcaf0;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-text-warning {
    --bs-btn-color: #ffc107;
    --bs-btn-hover-color: #ffc107;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 255, 193, 7;
    --bs-btn-active-color: #ffc107;
    --bs-btn-active-bg: #ffc10720;
    --bs-btn-active-border-color: #ffc107;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffc107;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #ffc107;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-text-danger {
    --bs-btn-color: #dc3545;
    --bs-btn-hover-color: #dc3545;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 220, 53, 69;
    --bs-btn-active-color: #dc3545;
    --bs-btn-active-bg: #dc354640;
    --bs-btn-active-border-color: #dc3545;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #dc3545;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #dc3545;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-text-light {
    --bs-btn-color: #f8f9fa;
    --bs-btn-hover-color: #f8f9fa;
    --bs-btn-focus-shadow-rgb: 248, 249, 250;
    --bs-btn-active-color: #f8f9fa;
    --bs-btn-active-bg: #f8f9fa20;
    --bs-btn-active-border-color: #f8f9fa;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #f8f9fa;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #f8f9fa;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-text-dark {
    --bs-btn-color: #212529;
    --bs-btn-hover-color: #212529;
    --bs-btn-focus-shadow-rgb: 33, 37, 41;
    --bs-btn-active-color: #212529;
    --bs-btn-active-bg: #21252940;
    --bs-btn-active-border-color: #212529;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #212529;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #212529;
    --bs-gradient: none;
    font-weight: 700;
}

/* New variant bootstrap btn-tab- */
.btn-tab-blue {
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-color: #6c757d;
    --bs-btn-hover-color: #102644;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #102644;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-tab-red {
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-color: #6c757d;
    --bs-btn-hover-color: #A60322;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 220, 53, 69;
    --bs-btn-active-color: #A60322;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-tab-primary {
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-color: #6c757d;
    --bs-btn-hover-color: #0d6efd;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #0d6efd;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-tab-secondary {
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-color: #6c757d;
    --bs-btn-hover-color: #343a40;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #343a40;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-tab-success {
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-color: #6c757d;
    --bs-btn-hover-color: #198754;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 25, 135, 84;
    --bs-btn-active-color: #198754;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-tab-info {
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-color: #6c757d;
    --bs-btn-hover-color: #0dcaf0;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 13, 202, 240;
    --bs-btn-active-color: #0dcaf0;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-tab-warning {
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-color: #6c757d;
    --bs-btn-hover-color: #ffc107;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 255, 193, 7;
    --bs-btn-active-color: #ffc107;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-tab-danger {
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-color: #6c757d;
    --bs-btn-hover-color: #dc3545;
    --bs-btn-hover-bg: #e9ecef;
    --bs-btn-focus-shadow-rgb: 220, 53, 69;
    --bs-btn-active-color: #dc3545;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-tab-light {
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-color: #ffffffa6;
    --bs-btn-hover-color: #fff;
    --bs-btn-focus-shadow-rgb: 248, 249, 250;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffffa6;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
    --bs-gradient: none;
    font-weight: 700;
}
.btn-tab-dark {
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-color: #6c757d;
    --bs-btn-hover-color: #212529;
    --bs-btn-focus-shadow-rgb: 33, 37, 41;
    --bs-btn-active-color: #212529;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
    --bs-gradient: none;
    font-weight: 700;
}

/* bootstrap dropdown */
.dropdown-primary {
    --bs-dropdown-color: #fff;
    --bs-dropdown-bg: #102644;

    --bs-dropdown-focus-color: #fff;
    --bs-dropdown-focus-bg: #102644;
    --bs-dropdown-focus-border-color: #102644;

    --bs-dropdown-disabled-color: #102644;
    --bs-dropdown-disabled-bg: transparent;
    --bs-dropdown-disabled-border-color: #102644;
}

/* Tema umss de sidebar */
.sidebar-umss {
    .sidebar {
        background-color: rgba($blue,1) !important;
        --bs-bg-opacity: 1;
        --bs-sidebar-color: rgba(255, 255, 255, 0.55);
        --bs-sidebar-hover-color: rgba(255, 255, 255, 0.75);
        --bs-sidebar-disabled-color: rgba(255, 255, 255, 0.25);
        --bs-sidebar-active-color: #fff;

        --bs-sidebar-header-color: #fff;
        --bs-sidebar-footer-color: #fff;

        .sidebar-menu {
            --bs-sidebar-item-menu-bg-color-rgb: 255, 255, 255;
        }
    }
}