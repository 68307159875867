.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dee2e6;
    height: 2.5rem;
    width: 2.5rem;

    &.avatar-sm {
        height: 2rem;
        width: 2rem;
    }
    &.avatar-lg {
        height: 3rem;
        width: 3rem;
    }

    &.avatar-circular {
        border-radius: 50%;
    }
    &.avatar-rounded {
        border-radius: 17.5%;
    }

    img {
        height: 100%;
        width: 100%;
    }
}