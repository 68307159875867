/* Estructura de la pagina */
.main-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
}

.main-container > .content-page {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.main-container > .header-page {
    display: flex;
    align-items: center;
    padding: 1rem 5% 1rem 10%;
    background-color: var(--umss-grey);
}

.content-page .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: white;
    padding: 1.5rem;
}

.content-page .title-page {
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;
    align-items: center;
    padding: .75rem 3rem;
    color: var(--umss-red);
    background-color: #fff;
    border-bottom: 3px solid var(--umss-red);
}

.content-page .title-page a {
    color: var(--umss-red);
}

.content-page .title-page .title-page-options {
    display: flex;
    align-items: center;
    margin-left: auto;
}
.content-page .title-page .title-page-options .btn {
    background-color: #fff;
    color: var(--umss-red);
    border: 1px solid var(--umss-red);
    padding: .25rem .75rem;
    white-space: nowrap;
}
.content-page .title-page .title-page-options .btn:not(:last-child) {
    margin-right: 1rem;
}

.container-login {
    position: absolute;
    height: 100%;
    width: 100%;
    padding-bottom: 5rem;
}

footer {
    background-color: var(--umss-blue);
    color: #fff;
    font-size: .75rem;
    padding: 1.5rem 0;
    text-align: center;
}

@media (min-width: 768px) {
    .content-page .content {
        padding: 1.75rem 2.5rem;
    }
}

/* -------------------------------- */

/* Login  */

.login-page {
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.login-header {
    display: flex;
    align-items: center;
    background-color: var(--umss-blue);
}

.login-container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}
.login-container .login {
    width: 22.5rem;
    max-width: 100%;
}
.login-container .welcome-container {
    display: flex;
    padding: 1.5rem;
    background-color: var(--umss-red);
}

@media (min-width: 768px) {
    .login-container {
        padding-right: 0;
        padding-left: 10%;
        flex-direction: row;
    }
    .login-container .login {
        width: 30rem;
    }
    .login-container .welcome-container {
        width: 100%;
        padding: 3rem;
    }
    .login-container .welcome-container .logo {
        width: 25%;
        margin: 0 2.5%;
    }
    .login-container .welcome-container .welcome {
        width: 75%;
    }
}

/* -------------------------------- */

/* Home  */

.home {
    background-color: var(--umss-grey);
}
.home-section {
    background-color: var(--umss-grey);
    padding: 2rem;
}

.home-section .home-text-1 {
    font-size: calc(2.5rem);
}
.home-section .home-text-2 {
    font-size: calc(1.275rem + .3vw) ;
}

@media (min-width: 768px) {
    .home-section {
        padding-right: 0;
    }
    .home-container {
        margin-left: 10%;
    }
    .home-section .home-text-1 {
        font-size: 3.5rem;
    }
}

/* -------------------------------- */

/* Filtros Solicitud  */
/* xl */
@media (min-width: 576px) {
    .datatable-toolbar .solicitud-filtro {
        width: 8.5rem !important;
    }
}

/* -------------------------------- */

.bg-blue {
    background-color: var(--umss-blue);
}

.highlight {
    background-color: #8BF878
}

#buttonDiv {
    scale: 1.1;
}

.fw-medium {
    font-weight: 500;
}

/* Table beneficiarios */

.table-beneficiarios {
    max-height: 20rem;
    overflow-y: auto;
}
.table-beneficiarios thead {
    position: sticky;
    top: 0;
}
.table-beneficiarios thead tr > * {
    background-color: #fff;;
}

/* Date Range Picker */
.rdrDateRangePickerWrapper.custom-date-range-picker .rdrDefinedRangesWrapper,
.rdrDateRangePickerWrapper.custom-date-range-picker .rdrDateDisplayWrapper {
    display: none;
}

.table-simple {
    width: 100%;
    border-collapse: collapse;
}

.table-simple th {
    background-color: #f0efef;
}

.table-simple th,
.table-simple td {
    padding: .25rem .75rem;
    border: 1px solid;
}

.bs-border-title {
    position: relative;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    padding: 1rem .5rem .5rem .5rem;
    margin-top: 1.25rem;
}
.bs-border-title .bs-title {
    position: absolute;
    top: -.75rem;
    padding: 0 .5rem;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb),var(--bs-bg-opacity));
}