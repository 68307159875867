.flex-1 {
    flex: 1;
}

.flip-vertical {
    transform: scale(-1, 1);
}

.flip-horizontal {
    transform: scale(1, -1);
}

.flip-both {
    transform: scale(-1, -1);
}

.cursor-pointer {
    cursor: pointer;
}

.min-w-100 {
    min-width: 100%;
}

.bi-fix::before {
    vertical-align: 0;
}

/* md > 768 */
@media (min-width: 768px) {
    .w-md-auto {
        width: auto !important;
    }
    .flex-first-md {
        order: 0 !important;
    }
}

/* lg > 992 */
@media (min-width: 992px) {
    .w-lg-75 {
        width: 75% !important;
    }
}

/* xl > 1200 */
@media (min-width: 1200px) {
    .w-xl-75 {
        width: 75% !important;
    }
    .w-xl-50 {
        width: 50% !important;
    }
}

/* xxl > 1400 */
@media (min-width: 1400px) {
    .w-xxl-75 {
        width: 75% !important;
    }
}

.z-index-1 {
    z-index: 1050;
}
.z-index-2 {
    z-index: 1060;
}
.z-index-3 {
    z-index: 1070;
}

// Group
.group {
    > :first-child:last-child {
        border-radius: 6px;
    }
    > :first-child:not(:last-child) {
        border-radius: 6px 0 0 6px;
    }
    > :last-child:not(:first-child) {
        border-radius: 0 6px 6px 0;
    }
    > :not(:first-child):not(:last-child) {
        border-radius: 0;
    }
}

.group-currency {
    display: flex;
    > input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    > .dropdown > .dropdown-select {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    
    }
}

.text-justify {
    text-align: justify;
}
.text-red {
    color: var(--umss-red);
}

.resize-v {
    resize: vertical;
    overflow: auto;
}

.resize-h {
    resize: horizontal;
    overflow: auto;
}

.fill-img {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}
.fill-img img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}

.fill-white {
    fill: #fff;
}

.none-label label {
    display: none;
}

.rounded-start-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important;
}

.rounded-end-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}

.bg-orange-50 {
    background-color: #fbe9e7;
}
.bg-green-50 {
    background-color: #f1f8e9;
}
.bg-blue-50 {
    background-color: #e1f5fe;
}
.bg-red-50 {
    background-color: #ffebee;
}
.bg-purple-50 {
    background-color: #f3e5f5;
}