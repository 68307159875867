.custom-input {
    display: flex;
    align-items: center;

    input {
        color: inherit;
        font-style: inherit;
        font-weight: inherit;
        background-color: inherit;
        text-align: inherit;
        border: none;
        width: 100%;
        outline: 0;
        border-radius: 0;
        padding: 0;
    }
    .input-icon-right, .input-icon-left {
        color: inherit;
        user-select: none;
    }

    .input-icon-right {
        margin-left: .5rem;
    }
    .input-icon-left {
        margin-right: .5rem;
    }
}

.custom-input.form-control {
    .input-icon-right, .input-icon-left {
        color: #a1a1a1;
    }
    &.custom-input-sm {
        min-height: calc(1.5em + .5rem + 2px);
        padding: .25rem .5rem;
        font-size: .875rem;
        border-radius: .25rem;
    }
    &.custom-input-lg {
        min-height: calc(1.5em + 1rem + 2px);
        padding: .5rem 1rem;
        font-size: 1.25rem;
        border-radius: .5rem;
    }
    &.disabled {
        background-color: #e9ecef;
        opacity: 1;
    }
    &.form-control:not(.disabled):not(.is-invalid):not(.is-valid).focus {
        color: #212529;
        background-color: #fff;
        outline: 0;
        border-color: #86b7fe;
        box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
    }
    &.form-control.is-invalid:not(.disabled).focus {
        color: #212529;
        background-color: #fff;
        outline: 0;
        border-color: #dc3545;
        box-shadow: 0 0 0 .25rem rgba(220,53,69,.25);
    }
}

.bottom-line {
    padding: .25rem;
    border-bottom: 2px solid var(--umss-blue-sky);
    color: var(--umss-blue-sky);

    .custom-input-icon {
        font-size: 1.25rem;
    }
}
