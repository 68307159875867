.bs-picklist {
    display: flex;

    .bs-picklist-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.25rem;
    }
    
    .bs-picklist-list-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        border-color: #ced4da;
    }
    
    .bs-picklist-header {
        background: #f8f9fa;
        padding: 1rem;
        border: 1px solid #dee2e6;
        border-bottom: 0 none;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    
    .bs-picklist-filter {
        padding: 1rem;
        border: 1px solid #dee2e6;
        border-bottom: 0 none;
    }
    .bs-picklist-filter:first-child {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    
    .bs-picklist-list {
        height: 100%;
        padding: .75rem 0;
        overflow-y: auto;
        border: 1px solid #dee2e6;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    
    .bs-picklist-list:first-child {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    
    .bs-picklist-item {
        padding: .5rem 1rem;
        cursor: pointer;
    }
    
    .bs-picklist-item.active {
        background-color: #0d6efd;
        color: #fff;
    }
}
