.fade-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1500;
    background-color: rgba(0, 0, 0, 0.5);

    &.hidden {
        display: none;
    }
}

.sidebar {
    height: 100% !important;
    max-width: 18rem;
    background-color: #fff;

    --bs-sidebar-color: rgba(0, 0, 0, 0.65);
    --bs-sidebar-hover-color: rgba(0, 0, 0, 0.8);
    --bs-sidebar-disabled-color: rgba(0, 0, 0, 0.3);
    --bs-sidebar-active-color: rgba(0, 0, 0, 1);

    --bs-sidebar-header-color: #000;
    --bs-sidebar-footer-color: #000;

    .sidebar-menu {
        height: 100%;
        width: 18rem;
        display: flex;
        flex-direction: column;
        background-color: inherit;

        --bs-sidebar-item-padding-y: .75rem;
        --bs-sidebar-item-padding-x: 1rem;
        --bs-sidebar-item-color: var(--bs-sidebar-color);
        --bs-sidebar-item-hover-color: var(--bs-sidebar-hover-color);
        --bs-sidebar-item-disabled-color: var(--bs-sidebar-disabled-color);
        --bs-sidebar-item-active-color: var(--bs-sidebar-active-color);
        --bs-sidebar-item-menu-bg-color-rgb: 0, 0, 0;

        .sidebar-header {
            width: 100%;
            padding: 1rem 1.5rem;
            border-bottom: 2px solid #999999;
            color: var(--bs-sidebar-header-color);
        }
        .sidebar-body {
            flex: 1;
            overflow-y: auto;
            .sidebar-item {
                display: flex;
                align-items: center;
                padding: var(--bs-sidebar-item-padding-y) var(--bs-sidebar-item-padding-x);
                color: var(--bs-sidebar-item-color);
                text-decoration: none;
                background: none;
                border: 0;
                border-bottom: 1px solid #999999;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
                cursor: pointer;

                &:hover, 
                &:focus {
                    color: var(--bs-sidebar-item-hover-color);
                }
                &.active, 
                &.show {
                    color: var(--bs-sidebar-item-active-color);
                }

                .sidebar-item-icon {
                    margin-right: 1rem;
                }
                .sidebar-item-label {
                    flex: 1;
                }
                .sidebar-item-icon-submenu {
                    margin-left: 1rem;
                }
            }
        }
        .sidebar-footer {
            width: 100%;
            padding: 1rem 1.5rem;
            border-top: 2px solid #999999;
            color: var(--bs-sidebar-footer-color);
        }
        .sidebar-item-menu-1 {
            background-color: rgba(var(--bs-sidebar-item-menu-bg-color-rgb), .05);
        }
        .sidebar-item-menu-2 {
            background-color: rgba(var(--bs-sidebar-item-menu-bg-color-rgb), .1);
        }
        .sidebar-item-menu-3 {
            background-color: rgba(var(--bs-sidebar-item-menu-bg-color-rgb), .15);
        }
        .sidebar-item-menu-4 {
            background-color: rgba(var(--bs-sidebar-item-menu-bg-color-rgb), .2);
        }
        .sidebar-item-menu-5 {
            background-color: rgba(var(--bs-sidebar-item-menu-bg-color-rgb), .25);
        }
    }
}

.sidebar-dark {
    .sidebar {
        background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity)) !important;
        --bs-bg-opacity: 1;
        --bs-sidebar-color: rgba(255, 255, 255, 0.55);
        --bs-sidebar-hover-color: rgba(255, 255, 255, 0.75);
        --bs-sidebar-disabled-color: rgba(255, 255, 255, 0.25);
        --bs-sidebar-active-color: #fff;

        --bs-sidebar-header-color: #fff;
        --bs-sidebar-footer-color: #fff;

        .sidebar-menu {
            --bs-sidebar-item-menu-bg-color-rgb: 255, 255, 255;
        }
    }
}