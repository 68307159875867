.bs-chip {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border-radius: 1rem;
    border: 1px solid #ced4da;

    & + .bs-chip {
        margin-left: .5rem;
    }
}

.bs-chip-label {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 .75rem;
    white-space: nowrap;
}
