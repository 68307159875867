.editable-select {
    .form-select:focus-visible {
        color: inherit;
        text-shadow: none;
    }

    .dropdown-menu {
        min-width: 100%;
        max-width: 100%;
        max-height: 240px;
        overflow-y: auto;

        .dropdown-item {
            user-select: none;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
    
            &.hover:not(.active) {
                color: #1e2125;
                background-color: #e9ecef;
            }
    
            &.active {
                color: var(--bs-dropdown-link-active-color);
                background-color: var(--bs-dropdown-link-active-bg);
            }
        }

        .dropdown-item:focus, 
        .dropdown-item:hover {
            color: inherit;
            background-color: inherit;
          }
    
        .dropdown-item-empty {
            user-select: none;
            font-weight: normal;
            padding: 0 1rem;
        }
    }

    .dropdown-select.is-invalid, 
    .was-validated .dropdown-select:invalid,
    .dropdown-select.is-valid, 
    .was-validated .dropdown-select:valid {
        background-position: right 2.5rem center, center right 2.25rem;
        .dropdown-label {
            padding-right: 1.5rem;
        }
    }
}

@media (min-width: 480px) {
    .editable-select .dropdown-menu {
        max-width: 480px;
    }
}
