/* Toast */
.bs-toast {
    position:fixed;
    width:25rem;
    z-index: 1090;
}
.bs-toast-message-content {
    display: flex;
    align-items: center;
}
.bs-toast-message {
    flex:1 1 auto
}
.bs-toast-detail {
    overflow-wrap: anywhere;
    white-space: pre-line;
}
.bs-toast-btn-close {
    display:flex;
    align-items:center;
    justify-content:center;
    overflow:hidden;
    position:relative
}

/* Position */
.bs-toast {
    &.top-right {
        top:20px;
        right:20px
    }
    &.top-left {
        top:20px;
        left:20px
    }
    &.bottom-left {
        bottom:20px;
        left:20px
    }
    &.bottom-right {
        bottom:20px;
        right:20px
    }
    &.top-center {
        top:20px;
        left:50%;
        transform:translateX(-50%)
    }
    &.bottom-center {
        bottom:20px;
        left:50%;
        transform:translateX(-50%)
    }
    &.center {
        left:50%;
        top:50%;
        min-width:20vw;
        transform:translate(-50%,-50%)
    }
}

/* Transition */
.bs-toast {
    .bs-toast-on-show {
        opacity:0;
        transform:translateY(50%)
    }

    .bs-toast-on-show-active {
        opacity:1;
        transform: translateY(0);
        transition: transform .3s,opacity .3s;
    }

    .show {
        transform: none;
    }

    .bs-toast-on-close {
        opacity: 1;
        max-height: 1000px;
    }

    .bs-toast-on-close-active {
        opacity: 0;
        max-height: 0;
        margin-bottom: 0 !important;
        overflow: hidden;
        transition: max-height .45s cubic-bezier(0,1,0,1), opacity .3s, margin-bottom .3s;
    }
}

/* Theme toast */
.bs-toast {

    .bs-toast-content {
        margin: 0 0 1rem 0;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }

    .bs-toast-message-content {
        padding: .75rem;
        border-width: 0 0 0 6px;
    }

    .bs-toast-content .bs-toast-message {
        margin: 0 0 0 1rem;
    }

    .bs-toast-content .bs-toast-icon {
        line-height: 1;
        font-size: 2rem;
        align-self: center;
    }

    .bs-toast-content .bs-toast-title {
        font-size: 1.1rem;
        font-weight: 700;
    }

    .bs-toast-content .bs-toast-detail {
        margin: 0.5rem 0 0 0;
    }
}

/* Button close */
.bs-toast {
    .bs-toast-btn-close {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 0;
        background: transparent;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

        &:hover {
            background: rgba(255, 255, 255, 0.3);
        }
        &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #C7D2FE;
        }
    }
    
    .bs-toast-content.info {
        background: #e9e9ff;
        border: solid #0d6efd;
        border-width: 0 0 0 6px;
        color: #0d6efd;

        .bs-toast-icon,
        .bs-toast-btn-close {
            color: #0d6efd;
        }
    }

    .bs-toast-content.success {
        background: #e4f8f0;
        border: solid #198754;
        border-width: 0 0 0 6px;
        color: #198754;

        .bs-toast-icon,
        .bs-toast-btn-close {
            color: #198754;
        }
    }

    .bs-toast-content.warning {
        background: #fff2e2;
        border: solid #ffc107;
        border-width: 0 0 0 6px;
        color: #ffc107;

        .bs-toast-icon,
        .bs-toast-btn-close {
            color: #ffc107; /* #cc8925 */
        }
    }

    .bs-toast-content.error {
        background: #ffe7e6;
        border: solid #dc3545;
        border-width: 0 0 0 6px;
        color: #dc3545;

        .bs-toast-icon,
        .bs-toast-btn-close {
            color: #dc3545;
        }
    }
    
    .bs-toast-content.primary {
        background: #e9e9ff;
        border: solid #0d6efd;
        border-width: 0 0 0 6px;
        color: #0d6efd;

        .bs-toast-icon,
        .bs-toast-btn-close {
            color: #0d6efd;
        }
    }

    .bs-toast-content.secondary {
        background: #ffe7e6;
        border: solid #6c757d; 
        border-width: 0 0 0 6px;
        color: #6c757d; 

        .bs-toast-icon,
        .bs-toast-btn-close {
            color: #6c757d; 
        }
    }
    
    .bs-toast-content.dark {
        background: #ffe7e6;
        border: solid #212529; 
        border-width: 0 0 0 6px;
        color: #212529; 

        .bs-toast-icon,
        .bs-toast-btn-close {
            color: #212529; 
        }
    }
    
    .bs-toast-content.light {
        background: #ffe7e6;
        border: solid #f8f9fa; 
        border-width: 0 0 0 6px;
        color: #f8f9fa; 

        .bs-toast-icon,
        .bs-toast-btn-close {
            color: #f8f9fa; 
        }
    }
}