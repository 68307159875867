.input-dropdown {
    --bs-dropdown-bg: #fff;
    --bs-dropdown-focus-bg: #fff;
    
    .dropdown-menu {
        min-width: 100%;
        max-width: 100%;
        max-height: 240px;
        overflow-y: auto;
    }

    .dropdown-item {
        user-select: none;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;

        &.hover:not(.active) {
            color: #1e2125;
            background-color: #e9ecef;
        }

        &.active {
            color: var(--bs-dropdown-link-active-color);
            background-color: var(--bs-dropdown-link-active-bg);
        }
    }

    .dropdown-select {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--bs-dropdown-color);
        background-color: var(--bs-dropdown-bg);

        .dropdown-label{
            display: block;
            word-wrap: normal;
            white-space: nowrap;
            overflow: hidden;
            flex: 1 1 auto;
            text-overflow: ellipsis;
            user-select: none;
        }

        &.form-control.is-invalid, .was-validated .form-control:invalid {
            background-position: right 2.5rem center,center right 2.25rem;
        }
    }
    
    .dropdown-filter {
        padding: 0 1rem .5rem 1rem;
    }

    .dropdown-item-empty {
        user-select: none;
        font-weight: normal;
        padding: 0 1rem;
    }

    .clear-icon {
        position: absolute;
        right: 2.25rem;
        display: inline-block;
        font-size: 1.25rem;
        line-height: 1;
    }

    .dropdown-clearable .dropdown-label {
        padding-right: 1.75rem;
    }

    .dropdown-icon {
        display: block;
        width: 1.5rem;
        text-align: center;
    }

    > .form-control {
        padding: .375rem .75rem .375rem .75rem;

        &:focus {
            color: var(--bs-dropdown-focus-color);
            background-color: var(--bs-dropdown-focus-bg);
            outline: 0;
            box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
        }
    }
}

@media (min-width: 480px) {
    .input-dropdown .dropdown-menu {
        max-width: 480px;
    }
}